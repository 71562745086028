<template>
  <div id="app" v-if="show">
    <transition :name="SkipSwitchName">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { EventBus } from '@/utils/event-bus'
import { getSignalR } from '@/utils/signal'
import { mapGetters } from 'vuex'
import { returnLang } from '@/utils/validate'

export default {
  name: 'App',

  data() {
    return {
      SkipSwitchName: '',
      show: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.tx > from.meta.tx) {
        this.SkipSwitchName = 'Skright'
      } else {
        this.SkipSwitchName = 'Skleft'
      }
    },
  },
  created() {
    this.redirectToFastest(window.apis)
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  mounted() {
    EventBus.$on('Login', () => {
      if (this.$store.getters.connection.state === 'Connected') {
        this.$store.getters.connection.stop()
      }
      this.getConnection()
    })
    EventBus.$on('Logout', () => {
      if (this.$store.getters.connection.state === 'Connected') {
        this.$store.getters.connection.stop()
      }
      this.getConnection()
    })
    // if (process.env.NODE_ENV === 'development') {
    //   new this.$vconsole()
    // }
  },
  methods: {
    returnLang,
    async redirectToFastest(targetDomains) {
      const tests = targetDomains.map(domain => this.testSpeed(domain));
      const results = await Promise.all(tests);
      results.sort((a, b) => a.time - b.time);
      const fastest = results.find(result => result.time < Infinity);
      if (fastest) {
        window.baseURL = fastest.domain
        EventBus.$emit('init', fastest.domain)
        setTimeout(() => { this.getbasic(); this.getConnection() }, 50);
      }
    },
    testSpeed(domain) {
      return new Promise((resolve) => {
        const startTime = performance.now();
        fetch(domain+'/img/1.png', { mode: 'no-cors' })
          .then(() => {
            const endTime = performance.now();
            resolve({ domain, time: endTime - startTime });
          })
          .catch(() => {
            resolve({ domain, time: Infinity });
          });
        setTimeout(() => {
          resolve({ domain, time: Infinity });
        }, 3000); // 3s超时跳过
      });
    },
    getConnection() {
      const connection = getSignalR()
      this.$store.commit('SET_CONNECTION', connection)
    },
    showCompanyName(lang, ICO) {
      let basicConfig = this.basicConfig
      let title = ''
      if (lang === 'zh') {
        title = basicConfig.CompanyName
      } else if (lang === 'en') {
        title = basicConfig.CompanyNameEnglish
      } else if (lang === 'ft') {
        title = basicConfig.CompanyNameTraditional
      } else {
        title = basicConfig.CompanyNameJapanese
      }
      document.title = title
      var link = document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = ICO
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    async getbasic() {
      const res1 = await userApi.getbasic()
      this.$store.commit('SET_BASIC', res1)
      let arr = []
      res1.LanguageSelect.forEach((item) => {
        arr.push(this.returnLang(item, true))
      })

      let localLang = ''
      const defaultLang =
        localStorage.getItem('language') ||
        this.returnLang(res1.DefaultLanguage)
      if (arr.indexOf(defaultLang) === -1) {
        // 如果默认语言不包含可用语言，则切换默认为默认语言的第一项
        localLang = arr[0]
      } else {
        localLang = defaultLang
      }
      this.showCompanyName(localLang, res1.ICO)
      this.$i18n.locale = localLang
      this.$store.commit('SET_LANG', localLang)
      localStorage.setItem('language', localLang)
      this.show = true
    },
  },
}
</script>

<style lang="stylus" scoped>
#app
  background #1f1f1f
</style>
